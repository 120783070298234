img {
  width: 100%;
  vertical-align: middle;
}

.spliter-container {
  display: flex;
  justify-content: space-between;

  .image-spliter {
    width: 49%;
    position: relative;
  }
}
  
.image-spliter,
.mover {
  overflow: hidden;
  -webkit-touch-callout: none;
    -webkit-user-select: none;
      -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
            img {
              object-fit: cover;
              height: 100%;
              overflow: hidden;
              -webkit-touch-callout: none;
                -webkit-user-select: none;
                  -khtml-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                        user-select: none;
            }
}

.img-left {
  position: absolute;
  top: 0;
  left:0;
}

.mover {
  position:absolute;
  width: 200px;
  height: 100%;
  top: 0;
  left:0;
  z-index: 2;
}
.mover:before,
.mover:after {
  position:absolute;
  left:50%;
  content: "";
  background: $color-main;
  cursor: -webkit-grab;
  cursor: grab;
}

.mover:before {
  top: 0;
  transform:translateX(-50%);
  width: 1px;
  height: 100%;
}
.mover:after {
  content: '<  >';
  color: white;
  top: 50%;
  transform:translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  opacity: .8;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@include mq($until: sm) {
  .spliter-container {
    flex-direction: column;
  
    .image-spliter {
      width: 100%;
      margin: 15px 0;
    }
  }
}
