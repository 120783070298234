.bread-crumb {
  display: flex;
  flex-wrap: wrap;
  margin: 0;

  &__item {
    display: flex;
    justify-items: flex-start;
    margin-top: 0;

    &:not(:last-child) {
      margin-right: 5px;

      &::after {
        content: "/";
        color: grey;
        align-self: center;
        margin-left: 5px;
        font-weight: 300;

      }
    }

    a {
      font-size: .9rem;
      display: flex;
      align-items: center;
      color: rgb(179, 179, 179);
      font-weight: 400;
      transition: color .3s;

      &:hover {
        color: black;
      }
    }

    &.active {
      a {
        pointer-events: none;
        text-decoration: underline;
      }
    }
  }

  @include mq($until: md) {

  }
}
