.subsite {
    .news-list {
        li {
            overflow: hidden;
            
            a {
                height: 40vw;
            }
        }
    }    
}

.news-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    overflow: hidden;
    gap: 30px;

    li {

        a {
            height: 55vw;
            display: flex;
            place-content: flex-end;
            background-color: $color-main;
            justify-content: flex-start;

            @include green-hover;
            
            * {
                color: white;
                text-align: center;
            }
    
            img {
                height: 70%;
                object-fit: cover;
                object-position: center top;
            }
        }

        &:nth-child(2n)  {
            a {
                background-color: white;

                * {
                    color: black;
                }
            }
        }

        @include mq($until: xxl) {
            a {
                height: 60vw;
            }
        }

        @include mq($until: xl) {
            a {
                height: 65vw;
            }
        }

        @include mq($until: lg) {
            a {
                height: 70vw;
            }
        }
    }

    &--promo {
        margin-bottom: 30px;
    }

    &__header-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__header {
        margin-bottom: 30px;
        &--grid{
            grid-column-start: span 4;
            margin-bottom: 0;
        }
    }

    &__link {
        color: $color-main;
    }

    &__description {
        height: 30%;
        padding: 2vw 4vw;
        width: 100%;
        z-index: 2;

        h3 {
            font-size: rem(28px);
            font-weight: 500;
        }

        div {
            font-size: rem(23px);
        }
    }

    @include mq($until: xxl) {
        &__description {
            padding: 2vw 2.5vw;

            h3 {
                font-size: rem(25px);
                font-weight: 500;
            }
    
            div {
                font-size: rem(20px);
            }
        }
    }

    @include mq($until: xl) {

        gap: 20px;

        &__description {

            h3 {
                font-size: rem(22px);
            }
    
            div {
                font-size: rem(18px);
            }
        }
    }

    @include mq($until: lg) {
        gap: 15px;
    }

    @include mq($until: md) {

        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, 1fr);

        li {
            margin: 0;

            a {
                height: 55vw;

                img {
                    height: 65%;
                }
            }
        }

        &__description {
            height: auto;
            max-width: 600px;
            margin: 0 auto;
        }
    }

    @include mq($until: xs) {
        li {
            margin: 0;
            a {
                height: 40vh;
            }
        }

        &__description {
            padding-bottom: 30px;
            div {
                text-align: justify!important;
            }
        }
    }
}