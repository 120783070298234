.columns-3 {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    
    .gallery__link {
        height: 20vw;
        width: 100%;
        background-size: cover;
    }

    @include mq($until: sm) {   
        .gallery__link {
            height: 35vw;
        }
    }

    @include mq($until: xs) {   
        .gallery__link {
            height: 50vw;
        }
    }

    &.gallery-list {
        @include mq($until: lg) {
            grid-template-columns: repeat(2,1fr);
        }
        @include mq($until: xs) {
            grid-template-columns: repeat(1,1fr);
        }
    }
}

.columns-4 {
    grid-template-columns: auto auto auto auto;
    .gallery__link {
            height: 20vw;
    }
}

.columns-2 {
    grid-template-columns: auto auto;
    .gallery__link {
        height: 19vw;
    }
}

.subsite {
    .gallery {
        margin: rem(50px) 0;
    }

    @include mq($until: md) {
        // margin: rem(20px) 0;
    }
}

.gallery {
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: rem(160px);

    &__heading {
        h3 {
            font-family: $font-family-extra;
            font-size: rem(65px);
        }

        a {
            opacity: 0.3;
            transition: all .3s;
            text-decoration: underline;
            font-size: rem(20px);
            text-decoration-thickness: 1px;

            &:hover {
                opacity: 1;
            }
        }

        margin: rem(80px);
        text-align: right;
    }
    

    &__item {

        align-self: self-start;
        a {
            display: block;
        }

        p {
            line-height: 1;
            margin: 7px 0 15px;
        }
    }

    &__images {
        display: grid;
        width: 65%;

        @media screen and (max-width: 991px) {
            grid-gap: 20px;
            grid-template-columns: auto auto auto;
            &.columns-2{
                grid-template-columns: auto auto;
                .gallery__link {
                    height: 25vw;
                }
            }
        }

        @media screen and (max-width: 575px) {
            grid-template-columns: auto;
            &.columns-2{
                grid-template-columns: auto;
                .gallery__link {
                    height: 48vw;
                }
            }
        }

        .gallery__link {
            transition: transform .3s;
            display: inline-block;
            overflow: hidden;
            background-size: cover;
            background-position: center center;
            height: 27vw;

            @include green-hover;

            @media screen and (max-width: 991px) {
                height: 17vw;
            }
            @media screen and (max-width: 575px) {
                height: 48vw;
            }
        }
    }

    &__img {
        width: 100%;
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
        transition: box-shadow .3s;
    }

    @include mq($until: md) {
        margin-bottom: rem(60px);
        flex-direction: column-reverse;

        &__images {
            grid-gap: 0;
            width: 100%;

            .gallery__link {
                height: 30vw;
            }
        }

        &__heading {
            margin: 1.5rem;
            margin-left: auto;
            margin-right: 0;

            h3 {
                font-size: rem(45px);
                margin-bottom: 5px;
            }
    
            a {
                font-size: rem(16px);
            }
        }

        .padding-container-left {
            margin-right: auto;
            margin-left: 0;
            text-align: left;
        }

    }

    @include mq($until: xs) {
        &__images {
            .gallery__link {
                height: 33.3vh;
            }
        }

    }
}


.position {
    &__images {

        &-right {

        }

        &-left {
            flex-direction: row-reverse;
        }
        
    }

    @include mq($until: md) {
        &__images {

            &-left {
                flex-direction: column-reverse;
            }
        }
    }
}