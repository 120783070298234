.to-top {
    position: fixed;
    bottom: 4%;
    right: 0;
    z-index: 1;
    cursor: pointer;
    transition: all .3s;
    opacity: .7;

    &__arrow {
        width: 40px;
        height: 40px;
        background-color: white;
        display: flex;
        border-radius: 50%;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        align-items: center;
        justify-content: center;
        color: black;
        font-size: 20px;
    }

    &:hover {
        opacity: 1;
        padding-bottom: 10px;
    }
}