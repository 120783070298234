.footer {

  &__nav {
    display: flex;
    justify-content: space-between;
  }

  &__menuList {
    display: flex;
  }

  &__menuItem {
    margin-right: 1.6vw;
  }

  &__menuLink {
    font-size: 1.2vw;
    opacity: .5;
    transition: all .3s;

    &:hover {
      opacity: 1;
    }
  }

  @include mq($until: lg) {
    &__menuLink {
      font-size: 1.8vw;
    }

  }

  @include mq($until: md) {
    &__menuLink {
      font-size: rem(22px);
    }

    &__menuList {
      margin: 0;
      flex-wrap: wrap;
      place-content: center;
    }

    &__menuItem {
      margin: 2vw;
    }
  }

  @include mq($until: md) {

    &__nav {

      flex-direction: column;
      align-items: center;

      .social-media-icons {
        ul {
          margin-top: 0;
        }
      }
    }

    &__menuList {
      margin: 0;
    }

  }

}

.social-media-icons {
  svg {
    height: 40px;
    margin-left: 15px;
    opacity: .5;
    transition: all .3s;

    &:hover {
      opacity: 1;
    }
  }

  @include mq($until: lg) {
    svg {
      height: 35px;
      margin-left: 5px;
    }
  }

  @include mq($until: md) {
    svg {
      height: 25px;
      margin: 5px;
    }
  }

  @include mq($until: sm) {
    svg {
      height: 25px;
      margin: 5px;
      width: 38px;
    }
  }
}

.footer-small{

  * {
    font-size: rem(14px);
    opacity: .6;
    transition: all .3s;
    &:hover {
      opacity: 1;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;

  &__realization{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span{
      transition: color .3s ease-in-out;
    }
    &:hover span{
      color: $color-main;
    }
    img{
      margin-left: 15px;
      width: 80px;
    }
  }

  @include mq($until: sm) {
    flex-wrap: wrap;

    img {
      width: 50px;
    }

    &__realization, &__copyright {
      margin-left: auto;
    }
  }
}

.footer-contact {
  display: flex;
  justify-content: space-between;

  &__map {
    width: 70%;
    position: relative;

    iframe {
      margin-left: -100px;
      width: calc(100% + 100px);
      z-index: 0;
      filter: grayscale(1);
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: calc(100% + 100px);
      height: 100%;
      top: 0;
      left: -100px;
      background-color: #ffaa001c;
      pointer-events: none;
    }
  }

  .brand {
    margin: 130px 0;
    z-index: 1;
    flex-direction: row;
    padding: 10vw 0;
    background-size: cover;
    background-repeat: no-repeat;

    * {
      text-align: left;
    }

    img {
      width: 3vw;
    }

    h2 {
      font-size: 1.6vw;
      letter-spacing: .5vw;
    }

    &__logo {
        margin-right: 40px;
    }

    &__more {
        font-size: .6vw;
        margin-top: 20px;
    }
  }

  @include mq($until: lg) {
    .brand {
      &__logo {
        margin-right: 15px;
        width: auto;
        img {
          width: 4vw;
        }
      }

      &__name {
        margin-bottom: 5px;
      }

      &__address {
        font-size: rem(13px);
      }
    }
    
    margin-bottom: 20px;
  }

  @include mq($until: sm) {
    flex-direction: column;

    &__map {
      width: calc(100% + 30px);
      height: 65vh;
      overflow: hidden;
      margin-left: -30px;
      display: flex;
      align-items: center;

      iframe {
        width: 150%;
        height: 200%;
      }

      &::after {
        width: calc(100% + 60px);
        left: -30px;
      }
    }

    .brand {
      margin-bottom: -60px;
      width: calc(100% - 30px);
      padding: 25px;
      place-content: start;
      background-position: center;
      margin-top: 30px;

      h2 {
        font-size: rem(16px);
        margin-top: 0!important;
        margin-bottom: 0!important;
      }

      img {
        width: 30px;
      }
    }
  }

  @include mq($until: xs) {

    .brand {
      height: auto;
      width: calc(100% - 15px);
    }

    &__map {
      width: calc(100% + 30px);
      height: 65vh;
      overflow: hidden;
      margin-left: -30px;
      display: flex;
      align-items: center;

      iframe {
        width: 150%;
        height: 200%;
      }

      &::after {
        width: calc(100% + 15px);
        left: -15px;
      }
    }
  }
}