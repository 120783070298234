.top {
    display: flex;
    height: 100vh;

    &__slider {
        width: 66.7%;
        margin: 0;
        height: 100%;

        *:not(.slick-dots) {
            height: 100%;
        }

        li {
            margin: 0;
        }

        img {
            width: 100%;
            object-fit: cover;
        }

        .slick-dots {
            position: absolute;
            display: flex;
            top: 92%;
            left: 0;
            z-index: 5;
            width: 100%;
            justify-content: center;

            button {
                height: 15px;
                width: 15px;
                border-radius: 50%;
                margin: 8px;
                font-size: 0;
                background-color: rgba(255, 255, 255, 0.466);
                border: black;
                transition: all .3s;
            }

            .slick-active {
                button {
                    background-color: white;
                }
            }
        }

    }

    @include mq($until: xs) {
        flex-direction: column;
        height: 90vh;
        &__slider {
            width: 100%;
            height: 90vh;
            position: absolute;
            top: 0;

            .slick-dots {
                top: 92%;
                z-index: 9;
            }
        }
    }
}

.brand {
    background-color: $color-main;
    height: 100%;
    width: 33.3%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;

    * {
        color: white;
    }

    h2 {
        font-weight: 300;
        font-size: 1.6vw;
    }

    &__address {
        font-weight: 300;
        font-style: italic;
        font-size: .85vw;
    }

    &__name {
        margin-top: 40px!important;
        font-size: 2vw;
        letter-spacing: .7vw;
        font-weight: 600!important;
    }

    &__more {
        text-decoration: underline;
        font-weight: 600;
        font-size: .8vw;
        margin-top: 30px;
    }

    @include mq($until: md) {
        h2 {
            font-size: 2.6vw;
            margin-top: 0;
        }

        &__name {
            font-size: 3vw;
            letter-spacing: 1vw;
        }

        &__more {
            font-size: 1.8vw;
            margin-top: 0;
        }

        &__logo {
            width: 60px;
        }
    }

    @include mq($until: xs) {
        z-index: 1;
        background-color: #{$color-main}be;
        height: 90vh;
        width: 100%;
        align-items: center;
        padding-left: 15%;
        flex-direction: row;
        text-align: left;
        justify-content: start;

        &__logo {
            margin-right: 20px;
        }

        &__name {
            font-size: 22px;
            margin-bottom: 16px;
        }

        h2 {
            font-size: 18px;
        }

        &__more {
            font-size: 13px
        }
    }
}

.timeline {

    margin: rem(140px) 0 rem(50px);
    width: 100%;
    overflow: hidden;
    position: relative;

    h2 {
        text-align: center;
    }

    &__years {
        text-align: center;
        display: flex;
        align-items: center;
        margin-top: 50px;
        justify-content: center;
    }

    .lines {
        position: absolute;
        width: calc(100% + 90px);
        display: flex;
        justify-content: space-between;
        margin: 0 -45px;
        top: 140px;
    }

    .line {
        width: 7vw;
        height: 1px;
        top: 50%;
        display: block;
        left: -10vw;
        background: linear-gradient(90.05deg, rgba(255, 255, 255, 0) 0%, #000000 30%, #000000 70%, rgba(255, 255, 255, 0) 100%);
    }
    
    &__year {
        width: 19%;
        position: relative;

        span {
            font-size: rem(60px);
            font-family: $font-family-extra;
            -webkit-text-stroke: 2px black;
            text-stroke: 2px black;
            color: white;
            font-weight: 600;
            transition: all .5s;
            cursor: pointer;
            display: block;

            &:hover {
                color: black;
            }
        }

        
        &.active {
            span {
                color: black;
                transform: scale(1.7);
                -webkit-text-stroke: 0px;
                text-stroke: 0px;
            }
        }

        &:last-child span{
            font-family: $font-family;
        }
    }

    &__content {
        margin-top: rem(60px);

        ul {
            display: flex;
            width: 600vw;
            position: relative;
            position: relative;
            left: -100vw;
            transition: all 1s;

            li {
                width: 100vw;
                list-style: none;
                align-items: center;
                display: flex!important;

                &::before {
                content: none;
                }

                p {
                    margin: 40px 0 40px 5vw;
                }

                & > div {
                    width: 44vw;
                }
            }
        }

        img {
            width: 40vw;
            height: 25vw;
            object-fit: cover;
        }
    }

    @include mq($until: xxl) {
        &__year {
            width: 17%;

            span {
                font-size: rem(55px);
            }
        }
    }

    @include mq($until: xl) {
        .line {
            width: 5vw;
        }

        .lines {
            position: absolute;
            width: calc(100% + 50px);
            margin: 0 -25px;
        }
    }

    @include mq($until: xxl) {
        &__year {
            width: 19%;
        }
    }

    @include mq($until: lg) {
        &__year {
            width: 20%;
            position: relative;

            span {
                font-size: rem(50px);
            }
        }
    }

    @include mq($until: md) {

        &__year {
            position: relative;

            span {
                font-size: 3.9vw;
            }
        }
    }

    @include mq($until: sm) {
        margin: rem(50px) 0 rem(20px);

        &__content {

            margin-top: rem(10px);
            ul li{
                flex-direction: column;

                & > div {
                    width: 100%;
                }

                p {
                    margin: 20px 0;
                    text-align: justify;
                }
            }

        }

        img {
            width: 100%;
            height: 35vh;
            object-fit: cover;
        }

        &__years {
            margin-top: rem(10px);
        }
            
        &__year {

            width: 20%;

            span {
                -webkit-text-stroke: 1px black;
                text-stroke: 1px black;
            }

            &.active {
                span {
                    transform: scale(1.5);
                }
            }
        }
    }

    @include mq($until: sm) {

    }
}

.text-block {
    text-align: center;
    margin: rem(50px) 0;

    &__content {
        max-width: 1140px;
        margin: 30px auto;
        padding: 25px;

        p {
            margin: 0;
        }
    }

    &.bg {
        padding-top: 30vw;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: top center;
        background-size: 40%;
        margin-top: 0;
    }

    @include mq($until: md) {
        margin: rem(30px) 0;
        &__content {
            margin: 0;
        }
    }

    @include mq($until: xs) {
        &__content {
            text-align: justify;
            padding: 15px;
        }
    }
}

.line {
    background: linear-gradient(90deg, rgba(4, 4, 4, 0.501408) 0%, rgba(4, 4, 4, 0) 0%, #4F4036 25%, #4F4036 75%, rgba(4, 4, 4, 0) 100%);
    display: block;
    height: 1px;
    width: 100%;
    opacity: .4;
}

.img-comp-container {
    position: relative;
    height: 200px; /*should be the same height as the images*/
  }
  
  .img-comp-img {
    position: absolute;
    width: auto;
    height: auto;
    overflow: hidden;
  }
  
  .img-comp-img img {
    display: block;
    vertical-align: middle;
  }
  
  .img-comp-slider {
    position: absolute;
    z-index: 9;
    cursor: ew-resize;
    /*set the appearance of the slider:*/
    width: 40px;
    height: 40px;
    background-color: #2196F3;
    opacity: 0.7;
    border-radius: 50%;
  }