// PX to REM - Usage: font-size: rem(37px);
@function rem($px, $base: $base-font-size) {
    @return ($px / $base) * 1rem;
}

@mixin green-hover {
    position: relative;
    
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        transition: all .4s;
    }

    &:hover::after {
        background-color: #{$color-main}6b;
    }
}