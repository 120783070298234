section.subsite {
    display: flex;
    justify-content: space-between;

    @include mq($until: md) {
        flex-direction: column-reverse;

        &.padding-container-left {
            padding-right: $padding-lg;
        }
    }

    @include mq($until: sm) {
        &.padding-container-left {
            padding-right: $padding-sm;
        }
    }

    @include mq($until: xs) {
        &.padding-container-left {
            padding-right: $padding-xs;
        }
    }
}

.subsite {

    &__content {
        margin-top: 20vh;    
        width: 100%;

        &.main-col {
            width: 60%;
        }

        h1 {
            margin-bottom: 5vh;
        }
    }

    &__aside {
        width: 30%;
        margin: 10vh 0;

        img {
            position: sticky;
            top: 10vh;
            height: 80vh;
            object-fit: cover;
        }
    }

    .news-list {

        li a img {
            height: 80%;
        }

        &__description {
            height: 20%;
            padding: 1.5vw 2vw;
        }
    }
    
    @include mq($until: xxl) {
        &__aside {
            width: 33%;
        }
    }
    
    @include mq($until: xl) {
        &__aside {
            width: 36%;
        }

        .news-list {
            li a {
                height: 50vw;
            }
        }
    }
    
    @include mq($until: lg) {
        &__aside {
            width: 38%;
        }

        &__content {
            &.main-col {
                width: 57%;
            }
        }
    }

    @include mq($until: md) {

        .news-list {
            li a {
                height: 80vw;
            }
        }

        &__aside {
            width: calc(100% + (#{$padding-lg} * 2));
            margin-top: 0;
            margin-left: -$padding-lg;
            margin-right:  $padding-lg;
            margin-bottom: 10px;
            
            img {
                height: 60vh;
                width: 100%;
            }
        }

        &__content {
            margin-top: 25px;

            &.main-col {
                margin-top: 0;
                width: 100%;
            }
        }

    }

    @include mq($until: sm) {
        .news-list {
            li a {
                height: 100vw;
            }
        }
        
        &__aside {
            width: calc(100% + 60px);
            margin-top: 0;
            margin-left: -30px;
            margin-right: 30px;
            
            img {
                height: 50vh;
            }
        }
    }

    @include mq($until: xs) {

        &__aside {
            width: calc(100% + 30px);
            margin-top: 0;
            margin-left: -15px;
            margin-right: 15px;
            
            img {
                height: 50vh;
            }
        }
    }
}

.return {
    display: flex;

    button {
        font-family: $font-family;
        border: none;
        transition: all .3s;
        margin-right: 25px;
        height: fit-content;
        padding: 0 15px;
        border-radius: 5px;
        
        &:hover {
            color: white;
            background-color: $color-main;
            i {
                color: white;
            }
        }
    }

    @include mq($until: xs) {
        margin-top: 10px;
        flex-direction: column;
        button {
            width: 50px;
            margin-bottom: 5px;
        }
    }
}