.header-wrapper {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  min-height: 90px;

  &.subsite {
    background-color: $color-main;
    justify-content: space-between;
  }

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__socialmedia {
    margin-left: 10.3%;
    margin-top: 50px;
    
    span {
      font-weight: 300;
      color: white;
    }

    svg {
      margin-left: 0!important;
      margin-right: 10px!important;
      height: 60px!important;
      fill: white;
    }

    .social-media-icons {
      margin-left: -2px;
    }
  }

  &__logo {
    width: 15px;
    display: flex;
    margin: 15px 0;
    align-items: center;

    span {
      color: white;
      margin-left: 15px;
      font-size: rem(12px);
      letter-spacing: 5px;
      font-weight: 500;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
  }

  &__nav {
    &--fullscreen {
      width: 67%;
      height: 100vh;
      background-color: #{$color-main}ed;
      position: absolute;
      top: 0;
      right: 0;
      z-index: 1;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
      width: fit-content;
      margin-left: 10%;
    }
  }

  &__menuItem {
    margin-left: 10px;
    padding: 0;

    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: white;

    &--fullscreen {
      font-size: rem(28px);
      font-family: $font-family-extra;
      font-weight: 700;
    }
    &:active, &--active, &--open, &:hover {
      text-decoration: underline;
      text-decoration-thickness: 1px;
    }
  }

  .social-media-icons {
    z-index: 2;

    &.subsite {
      svg {
        fill: white;
      }
    }

    li {
      margin-top: 0;

      svg {
        opacity: 1;
        height: 33px;
        margin-left: 6px;
      }
    }
  }

  @include mq($until: md) {

    &.subsite {
      margin: 0;
    }

    &__socialmedia {
      margin-left: 0;
      margin-top: 20px;

      ul {
        margin-top: 0;
      }
    }

    &__nav {
      &--fullscreen {
        width: 67%;
      }
    }

    &__menuList {
      &--fullscreen {
        margin-left: 0;
      }
    }

    @media (orientation: landscape) {
      &__menuItem {
        margin: 0!important
      }
      &__menuLink {

        &--fullscreen {
          font-size: 1rem;
        }
      }

      .social-media-icons li svg {
        width: 28px;
      }
    }
  }

  @include mq($until: sm) {
    &.subsite {
      margin: 0;
    }
  }
  
  @include mq($until: xs) {
    &.subsite {
      margin: 0;
    }

    &__socialmedia {
      text-align: center;

      ul {
        justify-content: center;

        svg {
          margin: 0 10px!important;
        }
      }
    }

    &__nav {
      &--fullscreen {
        width: 100%;
        z-index: 2;
      }
    }

    &__menuItem {
      &--fullscreen {
        align-self: center;
      }
    }

    &__menuList {
      &--fullscreen {
        margin: 0 auto;
      }
    }

    .social-media-icons {

      &.subsite {
        margin-right: 10px;
      }

      li svg {
        height: 22px;
        width: 32px;
        fill: white;
      }
    }

    min-height: unset;

    &__logo {
      width: 12px;

      margin: 13px 0;

      span {
        font-size: rem(11px);
      }
    }
  }
}