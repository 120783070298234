.history {
  @include mq($until: lg) {
    margin-top: 75px;
  }
}

.milestone {
  display: flex;
  align-items: center;
  margin-bottom: 150px;
  position: relative;

  &::after {
    content: '';
    height: 250px;
    width: 1px;
    background: linear-gradient(180deg, rgba(196, 196, 196, 0) 0%, #000000 25%, #000000 75%, rgba(196, 196, 196, 0) 100%);
    top: 90%;
    left: 130px;
    position: absolute;
  }
  
  &:last-of-type::after {
    content: none;
  }

  &__year {
    font-size: rem(100px);
    font-family: $font-family-extra;
    -webkit-text-stroke: 2px black;
    text-stroke: 2px black;
    color: white;
  }

  &__body {
    margin: 0 auto;
    width: 33vw;
  }

  &__head {
    h3 {
      font-weight: 700;
      margin-bottom: 50px;
    }
  }

  &__image {
    img {
      height: 20vw;
      width: 25vw;
      display: block;
      object-fit: cover;
    }
  }

  &__description {
    p {
        font-size: rem(16px);
    }
  }

  @include mq($until: xl) {
    margin-bottom: 100px;

    &__year {
      font-size: rem(80px);
    }

    &::after {
      content: '';
      height: 200px;
      top: 90%;
      left: 90px;

    }

    &__head {
      h3 {
        margin-bottom: 40px;
      }
    }
  }

  @include mq($until: lg) {
    &__body {
      margin: 0 auto;
      width: 43vw;
    }

    &__head {
      h3 {
        margin-bottom: 30px;
      }
    }
  }

  @include mq($until: md) {

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "a1 a3"
      "a2 a2";
    width: 100%;
    height: 100%;
    
    .a1 { grid-area: a1; }
    
    .a3 { grid-area: a3; }
    
    .a2 { grid-area: a2; }

    &__image {
      img {
        height: 35vw;
        width: 50vw;
      }
    }

    &__year {
      text-align: center;
    }

    &__body {
      width: 90%;
      place-self: flex-start;
      margin-top: 30px;
    }
    
    &::after {
      height: 70px;
      top: calc(100% + 15px);
      left: 50%;
    }
  }

  @include mq($until: xs) {
    &__year {
      font-size: rem(60px);
      text-align: left;
    }

    &__body {
      width: 95%;
      margin-left: 0;
    }

    &__description {
      text-align: justify;
    }
  }
}